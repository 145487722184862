/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_identity_pool_id": "ap-northeast-2:ae4f0123-ae93-45da-b12c-3407b6621ea2",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_6Bj263kYX",
    "aws_user_pools_web_client_id": "69p5fmojtn853fhf13stk4ing4",
    "oauth": {
        "domain": "dev-auth.smartiok.com",
        "scope": [
            "openid",
            "aws.cognito.signin.user.admin",
            "com.smartiok.gateway/api.admin"
        ],
        "redirectSignIn": "https://dev.homeiot.smartiok.com/login/check",
        "redirectSignOut": "https://dev.homeiot.smartiok.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
