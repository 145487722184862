/* eslint-disable no-lone-blocks */
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {CustomTable, SearchInputBar, BasicModal, CustomSelectBox} from 'components/molecules';
import store from 'scripts/store';
import Net from 'scripts/net';
import Common from 'scripts/common';
import {usePageAuth , useDidMountEffect} from "scripts/hooks";
import {toast} from "react-toastify";
import classNames from 'classnames';
import {AttributesContent} from "./modal/AttributesContent";
import {TestRecordList} from "./modal/TestRecordList";
import {HomenetAuth} from "./modal/HomenetAuth";
import {SaveRecord} from "./modal/SaveRecord";
import {HomenetDisconnect} from "./modal/HomenetDisconnect";
import {Checkbox} from "@material-ui/core";
import {useStateWithCallbackLazy} from "use-state-with-callback";
import {Connector} from "mqtt-react-hooks";
import {BatchExecute} from "./modal/BatchExecute";
import './exhuastiveTest.scss';

const options = {
    username: 'iok_developer',
    password: '1oK^kolon$2020'
};

const columnList = [
    {
        code:'reverseNo',
        title: 'No',
        width : '2',
    },
    {
        code: 'typeName',
        title: '장비 타입',
        width: '6',
    },
    {
        code: 'name',
        title: '장비명',
        width: '6',
    },
    {
        code: 'deviceId',
        title: 'DeviceID',
        width: '13',
    },
    {
        code: 'serialNumber',
        title: 'serialNumber',
        width: '4',
    },
    {
        code: '',
        title: '디바이스 상태',
        width: '4',
        tdClickEvent: true,
    },
    {
        code: 'controlMqtt',
        title: '제어',
        width: '6',
    }
];

export const ExhaustiveTest = (props) => {

    // useHistory 선언
    const user = props.userInfo;
    const programAuth = usePageAuth(props.userInfo.authList[0], sessionStorage.getItem('menuId'));
    const [searchCategory, setSearchCategory] = useState([
        [
            {code: '', name: '고객사 선택'}
        ],
        [
            {code: '', name: '현장명 선택'}
        ],
        [
            {code: '', name: '동 선택'}
        ],
    ]);
    const [selectedSiteName, setSelectedSiteName] = useState('');
    const [selectedDongName, setSelectedDongName] = useState('');
    const [tempData, setTempData] = useState([]);
    const [contentData, setContentData] = useStateWithCallbackLazy([]);
    const [testData, setTestData] = useState([]);
    const [clickedRowIdx, setClickedRowIdx] = useState(null);
    const [checkedList, setCheckedList] = useState([]);
    const [onlyTest, setOnlyTest] = useState(false);
    const [renderData, setRenderData] = useState();
    const [loadingDevice, setLoadingDevice] = useState(false);
    const [delegateUsername, setDelegateUsername ] = useState('');
    const [refreshLoading, setRefreshLoading] = useState(false);
    const [boolUsableToken, setBoolUsableToken] = useState(true);
    const [modalState, setModalState] = useState({
        open: false,
        data: [],
        clickedBtnName: '',
        className : true
    });
    const [selectUser, setSelectUser] = useState({
        code: '',
        name: ''
    });
    const [activation, setActivation] = useState([
        {
            code: '',
            name: '전체 세대 조회'
        },
    ]);
    const [searchState, setSearchState] = useState({
        searchCategory1: '',
        searchCategory2: '',
        searchCategory3: '',

    })

    const [excelMapState, setExcelMapState] = useState(new Map());
    const [deviceListState, setDeviceListState] = useState(new Map());
    const [interval , setInterval] = useState(false);

    useDidMountEffect(()=>{
        if(loadingDevice) return;
        setContentData([]);
        setTestData([]);
        setRefreshLoading(true);
        getDeviceStatus('selectUser').then(r => setRefreshLoading(false));
    },[selectUser])

    useDidMountEffect(()=>{
        loadData(tempData);
    },[tempData,clickedRowIdx,loadingDevice]);

    useEffect(()=>{
    },[loadingDevice])

    useEffect(()=>{
        //console.log("데이터 로드완");
    },[contentData, loadingDevice])

    useEffect(() => {

        store.openedPage = '전수 테스트';
        getCategoryList('');
    }, [])

    useDidMountEffect(()=>{
        resetHandler();
    },[onlyTest])

    useEffect(()=>{
    },[boolUsableToken])

    useEffect(()=>{
        setClickedRowIdx(null);
        setTestData([]);
        setTempData([]);
        setContentData([]);
        setLoadingDevice(false);
    },[selectedDongName])

    useDidMountEffect( ()=>{
        if(clickedRowIdx === null){
            setLoadingDevice(false);
        }else{
            getHomenetUser();
        }
    },[clickedRowIdx]);

    useEffect(()=>{
    },[selectUser]);

    useDidMountEffect(()=>{
        getDeviceStatus('activation').then(r => console.log('get Device'));
    },[activation]);

    useEffect(()=>{
        if(interval){
            resetHandler();
        }
        setInterval(false);
    },[activation, interval])

    useDidMountEffect(()=>{
        if(delegateUsername === '') {
            setBoolUsableToken(true);
            return;
        }
        setBoolUsableToken(false);
        Net.checkHomenetsLogin(delegateUsername, response => {
            if(response.status === 200 ){
                setBoolUsableToken(true);
                //console.log('정상 토큰');
            }else if (response.status === 400){
                alert('비정상 홈네트워크 토큰입니다., 홈넷 인증을 다시 한번 진행해 주세요.');
                openHomenetAuthModal();
            }else {
                alert('비정상 홈네트워크 토큰입니다, 추가 홈넷 인증 후 안된다면 관리자 문의');
                openHomenetAuthModal();

            }
        })
    },[delegateUsername]);

    useDidMountEffect(async ()=>{
        if(!modalState.open){
            const response2 = await Net.getHoListForCheckListBySiteAndDongAsync(selectedSiteName, selectedDongName);
            setTempData(response2.data);
        }
    },[modalState.open]);


    useDidMountEffect(()=>{
        async function fetchData(){
            const response2 = await Net.getHoListForCheckListBySiteAndDongAsync(searchState.searchCategory2, searchState.searchCategory3);
            setTempData(response2.data);
        }
        fetchData();

    },[searchState]);


    useEffect(()=>{
        //console.log("디바이스 종류" ,deviceListState);
        //console.log(tempData)
    },[deviceListState])

    const getHomenetUser = async () => {
        const response = await Net.getHomenetAuthorizationStatusListAsync(selectedSiteName,selectedDongName,clickedRowIdx);
        setSelectUser({
            code:'',
            name:'전체 세대 조회'
        });
        if(response.status === 200 ) {
            let newArray = [
                {code: '', name: '전체 세대 조회'}
            ];
            if(response.data.length === 0){
                setDelegateUsername('');
            }else{
                response.data.forEach((items,i) => {
                    if(i==0) setDelegateUsername(items.username);
                    newArray = [
                        ...newArray,
                        {
                            code: items.username,
                            name: items.username
                        }
                    ]
                });
            }
            setActivation(newArray);
        }else{
            setDelegateUsername('');
        }
    }



    const tdOnClickEvent = (title, e) => {
        getDeviceStatus('onClickTd');
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: e.status.state,
            clickedBtnName: title
        });
    }

    const getCube =  async (maxFloor, maxLine) => {
        return Array.from(Array(Number(maxFloor) + 1), () => Array(Number(maxLine) + 1).fill(''));
    }
    const loadRender = async (hoData, trueNum, falseNum, checkNum, etcNum, arr1, maxFloor, maxLine, checking) => {
        let excelMap = new Map();
        for(let i=1; i<=maxLine; i++){
            let checkLine = false;
            let tempArray = [];
            for( let j=0; j<maxFloor; j++){
                if(arr1[j][i] !== ''){
                    checkLine = true;
                    tempArray.push(arr1[j][i]);
                }
            }
            if(checkLine){
                excelMap.set(i,tempArray);
            }
        }

        // 엑셀 파일 만들기 시작 -> UseState(excelMapState)
        // setExcelMapState(excelMap);


        return <Fragment>
        <h6 className="mt-2 text-align-right">
            <span style={{color: 'rgb(17,17,17)'}}>전체 : </span>{hoData.length}
            <span style={{color: 'rgb(85,139,220)'}}>{' '} 성공 : {trueNum} </span>
            <span style={{color: 'rgb(235,135,140)'}}>{' '}실패 : {falseNum} </span>
            <span style={{color: 'rgb(234,206,133)'}}>{' '}보류 : {checkNum} </span>
            <span style={{color: 'rgb(59,58,57)'}}>{' '} 기타 : {etcNum} </span>
        </h6>
        <div className="row h-95 overflow-auto scroll-style">
        <table className=' mainTable mt-1 '>
            <tbody key={"apt"}>
        {
            arr1.reverse().map((list, index)=>{
                if(index >= maxFloor) return<></>
                return(
                    <tr key={index}>
                        <th key={maxFloor-index} className="tableHead borderC-eb878c w-50px">{maxFloor-index}층</th>
                        <>
                            {arr1[index].map((list2, index2)=>{
                                if(index2 > maxLine-1) return null;
                                let checked = '';
                                let clicked = '';
                                if(checking.get(arr1[index][index2+1]) !== undefined){
                                    checked = checking.get(arr1[index][index2+1])+'-bg';
                                }
                                if(checked !== ''){
                                    clicked = arr1[index][index2+1] === clickedRowIdx ? checking.get(arr1[index][index2+1])+'-clickedBg':'';
                                }else{
                                    clicked = arr1[index][index2+1] === clickedRowIdx ? 'clickedTd':'';
                                }
                                return (<>
                                    {
                                        arr1[index][index2+1] === '' ? <td key={index+"F"+index2+"no"} className="text-center w-70px">{arr1[index][index2+1]}</td>
                                            : <td key={index+"F"+index2} className={!loadingDevice ? (classNames.bind()('text-center hoverbtn w-70px',clicked, checked)): classNames.bind()('w-70px text-center',clicked, checked)} onClick={()=>{ClickRow(arr1[index][index2+1])} }>{arr1[index][index2+1]}</td>
                                    }
                                    </>
                                )
                            })}
                        </>
                    </tr>
                )
            })
        }
            </tbody>
        </table>
        </div>

    </Fragment>
    }
    const loadData = async (hoData) => {
        let trueNum = 0;
        let falseNum = 0;
        let checkNum = 0;
        let etcNum = 0;
        let maxFloor = 0;
        let maxLine = 0;
        let checking = new Map();

        await hoData.reverse().forEach(items=> {
            const floor = Number(items.ho.slice(0, items.ho.length - 2));
            const line = Number(items.ho.slice(-2));

            maxFloor = floor > maxFloor ? floor : maxFloor;
            maxLine = line > maxLine ? line : maxLine;
        });

        let arr1 = await getCube(maxFloor, maxLine);

        await hoData.forEach(items=>{
            if(items.hasOwnProperty("lastCheck")){
                checking.set(items.ho, items.lastCheck.check);
                if(items.lastCheck.check === 'true'){
                    trueNum += 1;
                }else if( items.lastCheck.check === 'false'){
                    falseNum += 1;
                }else if( items.lastCheck.check === 'check'){
                    checkNum += 1;
                }else if( items.lastCheck.check === 'etc'){
                    etcNum += 1;
                }
            }
            const floor = Number(items.ho.slice(0, items.ho.length-2));
            const line = Number(items.ho.slice(-2));
            arr1[floor][line] = items.ho;
        })
        const dom = await loadRender(hoData, trueNum, falseNum, checkNum, etcNum, arr1, maxFloor, maxLine, checking);
        await setRenderData(dom);
    }

    const getDeviceStatus = async (props) => {
        let response = [];
        if(loadingDevice) return;
        if(clickedRowIdx === null) return;
        setLoadingDevice(true);

        if(selectUser.code === ''){
            response = await Net.getDeviceStatusByDongHoAsync(selectedSiteName, selectedDongName, clickedRowIdx);
        }else {
            response =  await Net.getDeviceStatusByUserAsync(selectUser.code);
            await response.data.forEach(item => {
                item.name = item.deviceNickname;
            })
        }

        if (response.data) {
            if (response.data.length > 0) {
                let tempTestList = [];
                let tempList = [];
                const temp = await response.data.map(item => {
                    if (item.hasOwnProperty('status') && (item.status !== null)) {
                        if (item.status.hasOwnProperty('state') && (item.status.state !== null)) {
                            if (item.status.state.hasOwnProperty('reported') && (item.status.state.reported !== null)) {
                                if (item.status.state.reported.hasOwnProperty('operations') && (item.status.state.reported.operations !== null)) {
                                    item.status.state.reported.operations.forEach(operationItem => {
                                        if (operationItem.code === 'power') {
                                            {/*
                                           1. 코드에 'power'가 있는 디바이스 기기 찾기.
                                           2. 해당 기기 deviceID, attribute, operations State에 담기
                                           3. response.data 에 stateValue on/off 넣기
                                        */
                                            }
                                            let tempAttributes = [];
                                            if (item.status.state.reported.hasOwnProperty('attributes')) {
                                                tempAttributes = item.status.state.reported.attributes;
                                            }
                                            if (item.type === "HEATER") {
                                                item.status.state.reported.operations.forEach(tempItem => {
                                                    if (tempItem.code === 'settingTemperature') {
                                                        const tempData = {
                                                            deviceId: item.deviceId,
                                                            attributes: tempAttributes,
                                                            operations: item.status.state.reported.operations,
                                                            name: item.name,
                                                            serialNumber: item.serialNumber,
                                                            type: item.type,
                                                            typeName: item.typeName,
                                                            status: item.status,
                                                            stateValue: operationItem.value,
                                                            stateTemperature: tempItem.value,
                                                            modelCode : item.modelCode
                                                        }
                                                        tempTestList.push(tempData);
                                                        item.stateValue = operationItem.value;
                                                        item.stateTemperature = tempItem.value;
                                                    }
                                                });
                                            } else if (item.modelCode === "DIMMING_LIGHT_BULB"){
                                                item.status.state.reported.operations.forEach(tempItem => {
                                                    if (tempItem.code === 'brightness') {
                                                        const tempData = {
                                                            deviceId: item.deviceId,
                                                            attributes: tempAttributes,
                                                            operations: item.status.state.reported.operations,
                                                            name: item.name,
                                                            serialNumber: item.serialNumber,
                                                            type: item.type,
                                                            typeName: item.typeName,
                                                            status: item.status,
                                                            stateValue: operationItem.value,
                                                            modelCode : item.modelCode,
                                                            brightness : tempItem.value
                                                        }
                                                        tempTestList.push(tempData);
                                                        item.stateValue = operationItem.value;
                                                        item.brightness = tempItem.value
                                                    }
                                                });
                                            }else if (item.modelCode === "SYSTEM_AIR_CONDITIONER" || item.modelCode === "AIR_CONDITIONER" || item.modelCode === "SYSTEM_AIR_CONDITIONER_LITE"){
                                                item.status.state.reported.operations.forEach(tempItem => {
                                                    if (tempItem.code === 'airConditionSettingTemperature') {
                                                        const tempData = {
                                                            deviceId: item.deviceId,
                                                            attributes: tempAttributes,
                                                            operations: item.status.state.reported.operations,
                                                            name: item.name,
                                                            serialNumber: item.serialNumber,
                                                            type: item.type,
                                                            typeName: item.typeName,
                                                            status: item.status,
                                                            stateValue: operationItem.value,
                                                            stateAirConditionTemperature: tempItem.value,
                                                            modelCode : item.modelCode
                                                        }
                                                        tempTestList.push(tempData);
                                                        item.stateValue = operationItem.value;
                                                        item.stateAirConditionTemperature = tempItem.value;
                                                    }
                                                });
                                            } else {
                                                const tempData = {
                                                    deviceId: item.deviceId,
                                                    attributes: tempAttributes,
                                                    operations: item.status.state.reported.operations,
                                                    name: item.name,
                                                    serialNumber: item.serialNumber,
                                                    type: item.type,
                                                    typeName: item.typeName,
                                                    status: item.status,
                                                    stateValue: operationItem.value,
                                                    modelCode : item.modelCode
                                                }
                                                tempTestList.push(tempData);
                                                item.stateValue = operationItem.value;
                                            }
                                        } else if (operationItem.code === 'valve') {
                                            const tempData = {
                                                deviceId: item.deviceId,
                                                operations: item.status.state.reported.operations,
                                                name: item.name,
                                                serialNumber: item.serialNumber,
                                                type: item.type,
                                                typeName: item.typeName,
                                                status: item.status,
                                                stateValue: operationItem.value,
                                                modelCode : item.modelCode
                                            }
                                            tempTestList.push(tempData);
                                            item.stateValue = operationItem.value;
                                        }
                                    })
                                }
                            }
                        }
                    }
                    tempList.push(item);
                    return item;
                })

                let tempList_ = [...tempList];
                let tempTestList_ = [...tempTestList];
                tempList_.sort(sort_device);
                tempTestList_.sort(sort_device)
                setContentData(tempList_);
                setTestData(tempTestList_);
            } else {
                setContentData([]);
                setTestData([]);
            }
            setLoadingDevice(false);
        } else {
            setContentData([]);
            setTestData([]);
            setLoadingDevice(false);
        }
    }

    function sort_device(a,b) {
        return ((b.modelCode === "BATCH_OFF_LIGHT_SWITCH") - (a.modelCode === "BATCH_OFF_LIGHT_SWITCH") ||
            (b.type === "LIGHT") - (a.type === "LIGHT") ||
            (b.type === "OUTLET") - (a.type === "OUTLET") ||
            (b.type === "HEATER") - (a.type === "HEATER") ||
            (b.modelCode === "AIR_CONDITIONER") - (a.modelCode === "AIR_CONDITIONER") ||
            (b.modelCode === "SYSTEM_AIR_CONDITIONER") - (a.modelCode === "SYSTEM_AIR_CONDITIONER") ||
            (b.type > a.type ? -1 : 0) || (b.name > a.name ? -1 : 0));
    }

    const ClickRow = (ho) => {
        if(!loadingDevice){
            setClickedRowIdx(ho);
        }
    }

    const onSubmitHandler = async (e) => {
        if (e.selectedCategory[0] === '') {
            alert("고객사를 선택해주세요.");
            return false;
        } else if (e.selectedCategory[1] === '') {
            alert("현장을 선택해주세요.");
            return false;
        } else if (e.selectedCategory[2] === '') {
            alert("동을 선택해주세요.");
            return false;
        }

        setSelectedSiteName(e.selectedCategory[1]);
        setSelectedDongName(e.selectedCategory[2]);

        setSearchState({
            searchCategory1: e.selectedCategory[0],
            searchCategory2: e.selectedCategory[1],
            searchCategory3: e.selectedCategory[2],
        });
    }

    const getCategoryList = () => {
        Common.getCustomerList((response)=>{
            let responseData = [];
            if(user.menuGroup == 'OPERATOR'){
                responseData = response.filter(x => {
                    if((x.code === "") || (x.code === user.customer.code )){
                        return x;
                    }
                });
            }else if(user.menuGroup == 'OPERATOR_TEST') {
                responseData = response.filter(x => {
                    if((x.code === "") || (x.code === user.customer.code )){
                        return x;
                    }
                });
            }
            else {
                responseData = response;
            }
            setSearchCategory([responseData,searchCategory[1],searchCategory[2]]);
        });
    }

    const getRelateCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getSitesList(selectedCategory, (response2) => {
            let responseData = [];
            if (user.menuGroup === 'OPERATOR') {
                responseData = response2.filter(x => {
                    if ((x.code === "") || (x.code === user.site.code)) {
                        return x;
                    }
                });
            } else if( user.menuGroup === 'OPERATOR_TEST'){
                responseData = response2.filter(x => {
                    if ((x.code === "") || (x.code === user.site.code)) {
                        return x;
                    }
                });
            }else {
                responseData = response2;
            }

            setSearchCategory([
                searchCategory[0],
                responseData,
                searchCategory[2]
            ]);
        });
    }

    const getRelateSiteCategory = (selectedCategory) => {
        if (selectedCategory === "reset") selectedCategory = "";
        Common.getDongListBySiteCode(selectedCategory, response => {
            setSearchCategory([
                searchCategory[0],
                searchCategory[1],
                response.data
            ])
        });
    }

    const sendData = (e) => {
        setModalState({
            ...modalState,
            data: e,
        })
    }

    const deviceControlHandler = (value) => {
        const controlList = checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const deviceId = nodeTr.querySelector('td[name=deviceId]').textContent;
            return deviceId;
        });
        if(checkedList.length > 1){
            toast('선택'+value,{
                autoClose : 1000,
                pauseOnHover: false,
                draggable : false,
                position : "top-right"
            });

        }

        controlList.forEach( controlItem => {
            testData.forEach( dataItem => {
                if(controlItem === dataItem.deviceId && dataItem.modelCode !== "BATCH_OFF_LIGHT_SWITCH"){
                    if(dataItem.type === "OUTLET"){
                        dataItem.operations = [{
                            code: 'power',
                            value: value
                        }];
                        // if(dataItem.name.includes('안방')){
                        //     setTimeout(()=>{
                        //         Net.deviceControl(dataItem);
                        //     },[8550]);
                        // }
                        Net.deviceControl(dataItem);

                    }else if(dataItem.type === "LIGHT"){
                        dataItem.operations = [{
                            code: 'power',
                            value: value
                        }];

                        // if(dataItem.name.includes('안방') || dataItem.name.includes('침실')) {
                        //     setTimeout(()=>{
                        //         Net.deviceControl(dataItem);
                        //     },[9450]);
                        // }
                        Net.deviceControl(dataItem);
                    }
                    else  {
                        dataItem.operations = [{
                            code: 'power',
                            value: value
                        }];
                        setTimeout(()=>{
                            Net.deviceControl(dataItem);
                        },[250]);
                    }
                }
            })
        });
    }

    const openHomenetDisconnectModal = () => {
        setModalState({
            ...modalState,
            open : !modalState.open,
            propsData : {
                siteCode : selectedSiteName,
                dong : selectedDongName,
                ho : clickedRowIdx,
            },
            clickedBtnName : '홈넷 해지',
            className : false
        });
    }

    const openHomenetAuthModal = () => {
        setModalState({
            ...modalState,
            open : !modalState.open,
            propsData : {
                siteCode : selectedSiteName,
                dong : selectedDongName,
                ho : clickedRowIdx,

            },
            clickedBtnName : '홈넷 인증',
            className : false
        });
    }

    const openTestRecordModal = () => {
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                siteCode : selectedSiteName,
                dong : selectedDongName,
                ho : clickedRowIdx
            },
            clickedBtnName: '테스트 기록 조회',
            className : false
        });
    }

    const openSaveRecordModal = () => {
        const passList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const deviceId = nodeTr.querySelector('td[name=deviceId]').textContent;
            const deviceName = nodeTr.querySelector('td[name=name]').textContent;
            passList.push({deviceId: deviceId, name:deviceName});
        });

        setModalState({
            ...modalState,
            open: !modalState.open,

            propsData: {
                siteCode : selectedSiteName,
                dong : selectedDongName,
                ho : clickedRowIdx,
                passList : passList,
                userInfo : user
            },
            clickedBtnName: '기록 저장',
            className : false
        });
    }

    const resetHandler = () => {
        setContentData([]);
        setTestData([]);
        getDeviceStatus('reset').then(r => console.log('reset'));
    }

    const reload = async (e) => {
        if(e === 'reload'){
            setTestData([]);
            setContentData([]);
            setTimeout(()=>{
                Net.checkHomenetsLogin(delegateUsername, response => {
                    if(response.status === 200 ){
                        setBoolUsableToken(true);
                        //console.log('정상 토큰');
                    }else if (response.status === 400){
                        setBoolUsableToken(false);
                        alert('비정상 홈네트워크 토큰입니다.');
                        openHomenetAuthModal();
                    }
                })
                getHomenetUser();
            },[3000]);
            setTimeout(()=>{
                setInterval(true);
            },[4600])
        }
    }

    const setStateValue = (deviceId, value) => {
        setContentData(contentData.map((data, i) =>{
            if(data.deviceId === deviceId) {
                data.stateValue = value;
            }
            return data;
        }));
        setTestData(testData.map((data)=>{
            if(data.deviceId === deviceId) {
                data.stateValue = value;
            }
            return data;
        }))
    }

    const selectBoxHandler = (name, value) => {
        switch (name) {
            case 'selectUser':
                setSelectUser({
                        code: value.code,
                        name: value.name
                    }
                );
                break;
            default:
                break;
        }
    }

    function getEntireStatusHandler() {
        setRefreshLoading(true);
        Net.getDevicesStatus(delegateUsername, response =>{
            setRefreshLoading(false);
        });
        setTimeout(()=>{
            setRefreshLoading(false);
        },[6000])
    }

    function getStatusHandler() {
        setRefreshLoading(true);
        let checkList = [];
        checkedList.map((id) => {
            const node = document.querySelector(`#${id}`);
            const nodeTr = node.closest('tr');
            const deviceId = nodeTr.querySelector('td[name=deviceId]').textContent;
            const deviceName = nodeTr.querySelector('td[name=name]').textContent;
            checkList.push({deviceId: deviceId, name:deviceName});
        });
        setTimeout(()=>{
            setRefreshLoading(false);
        },[5000])
        Net.getDeviceStatusListByUserAndDeviceId(checkList,delegateUsername, (response) => {
                if (response) {
                    console.log(response);
                    setRefreshLoading(false);
                }
        });
    }

    function openBatchExecuteModal(){
        setModalState({
            ...modalState,
            open: !modalState.open,
            propsData: {
                siteCode : selectedSiteName,
                dong : selectedDongName,
                ho : clickedRowIdx
            },
            clickedBtnName: '일괄 제어',
            className: true

        });
    }

    return (
        <div className="w-100 h-100">
            <Connector brokerUrl="wss://b-7098f301-eed4-48ad-86ee-e9864e430997-1.mq.ap-northeast-2.amazonaws.com:61619"
                       options={options}>
        <div className="h-100">
            <BasicModal
                className={classNames('w-80', 'h-93', { 'w-50 h-70' : modalState.className === true})}
                title={`${modalState.clickedBtnName} 목록`}
                content={
                    <ModalContent
                        sendData={sendData}
                        modalType={modalState.clickedBtnName}
                        propsData={modalState.propsData}
                        modalClose={(e) => setModalState({
                            ...modalState,
                            open: e
                        })}
                        reload={reload}
                        deleteAuth = {programAuth.deleteAuth}
                    />
                }
                modalOpen={modalState.open}
                modalClose={() => setModalState({
                    ...modalState,
                    open: false
                })}
                sendData={modalState.data}
            />
            <div className="w-100 h-1px bgC-efefef"/>
            <div className="h-100 row pt-2">
                    <SearchInputBar
                        role={user.menuGroup}
                        className="h-1"
                        searchCategory={true}
                        searchCategoryGroup={searchCategory}
                        searchBtn={true}
                        searchBtnTitle="검색"
                        dynamicSearch={false}
                        relateCategoryHandler={getRelateCategory}
                        relateSiteCategoryHandler={getRelateSiteCategory}
                        onSubmit={onSubmitHandler}
                    />
                <div className="w-40 h-83 mt-2">
                    {
                        selectedDongName ? <div className="flex justify-content-between">
                                <h5 className="ml-5 mb-2"> 동별 테스트 현황 : {selectedDongName} 동 </h5>
                                <button
                                    onClick={openBatchExecuteModal}
                                    className="downloadBtn col-2"> 일괄 제어 </button>
                        </div>
                        : <></>
                    }
                    <div id="container" className="h-100 mt-2 ">
                        {
                            tempData.length > 0 ? renderData : <></>
                        }
                    </div>
                    {
                        tempData.length>0 ?  <h6 className=" text-align-right">
                            성공 : <span style={{background: 'rgb(85,139,220)'}}>&ensp;&ensp;&ensp;</span>
                            실패 : <span style={{background: 'rgb(235,135,140)'}}>&ensp;&ensp;&ensp;</span>
                            보류 : <span style={{background: 'rgb(234,206,133)'}}>&ensp;&ensp;&ensp;</span>
                            기타 : <span style={{background: 'rgb(59,58,57)'}}>&ensp;&ensp;&ensp;</span>
                        </h6> : <></>
                    }

                </div>
                <div className="w-60 h-83">
                    <h5 className="mt-1p mb-1 ml-5 row">{
                        selectedDongName ? <>
                            <div className="row">
                                {
                                    clickedRowIdx === null ? <></> : <>
                                            <div className="col-6">
                                                <button className="removeBtn w-60 mr-5 mb-2"
                                                    onClick={openTestRecordModal}>테스트 기록 조회
                                                </button>
                                                <button className="cancelBtn w-36 mb-2 " onClick={openSaveRecordModal}>기록 저장</button>
                                            </div>
                                        {
                                            (user.menuGroup === "OPERATOR" || user.menuGroup === "OPERATOR_TEST") ? <></> :
                                            <div className="col-6 flex justify-content-end">
                                                <button className="addBtn col-4 mr-5 mb-2 "
                                                        onClick={openHomenetAuthModal}>홈넷 인증
                                                </button>
                                                <button className="cancelBtn col-4 mr-5 mb-2 "
                                                        onClick={openHomenetDisconnectModal}>홈넷 해지
                                                </button>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            <div className="row">
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <h4 className="">
                                        {selectedDongName} 동 {clickedRowIdx} 호 디바이스 제어
                                    </h4>
                                    <h6 className="mt-2 ml-5">
                                        전체 디바이스 : {contentData.length} 테스트 가능 디바이스 : {testData.length}
                                    </h6>
                                 </div>
                                <div className="col-6 flex justify-content-end">
                                <button className="addBtn col-4 mr-5 mb-2 " onClick={()=>deviceControlHandler('on')}>선택 ON</button>
                                <button className="cancelBtn col-4 mr-5 mb-2 " onClick={()=>deviceControlHandler('off')}>선택 OFF</button>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-6">
                                    <span className="text-muted mt-2 ml-10">테스트 디바이스만</span>
                                    <Checkbox
                                        checked={onlyTest}
                                        onChange={()=> setOnlyTest(!onlyTest)}
                                        disabled={loadingDevice}
                                        size="small"
                                    />
                                </div>

                                <div className="col-6 flex justify-content-end">
                                    <div className="col-5 mr-8">
                                        <CustomSelectBox
                                            name="activation"
                                            className="mb-2 col-12"
                                            categoryList={activation}
                                            value={selectUser.code}
                                            onChangeHandler={(value, name) => selectBoxHandler('selectUser', {
                                                code: value,
                                                name: name
                                            })}
                                            callbackCodeName="Y"
                                            disabled={loadingDevice}
                                        />
                                    </div>

                                    { (user.menuGroup === "OPERATOR" || user.menuGroup === "OPERATOR_TEST") ? <> </>
                                        : <>
                                            <button className="addBtn col-4 mr-5 mb-2" disabled={refreshLoading || !boolUsableToken} onClick={getEntireStatusHandler}>상태 재조회  </button>
                                            <button className="removeBtn col-4 mr-5 mb-2" disabled={refreshLoading || !boolUsableToken} onClick={getStatusHandler}>개별 상태 조회  </button>

                                        </>
                                    }
                                <button className="downloadBtn col-4 mr-5 mb-2" disabled={refreshLoading} onClick={resetHandler}>새로 고침  </button>
                                </div>
                            </div>
                            </> : <></>
                    }</h5>
                    {
                        selectedDongName ? <CustomTable
                            className=" overflowYscroll h-60vh"
                            columnList={columnList}
                            contentData={onlyTest ? testData : contentData}
                            tdClickEvt={tdOnClickEvent}
                            checkbox={true}
                            checkedList={(checkedList) => {
                                setCheckedList(checkedList)
                            }}
                            setCheckFalse={false}
                            setStateValue={(deviceId, value) => {setStateValue(deviceId, value)}}
                            currentPage={'0'}
                            rowPerPage={'99999'}
                            totalElementsCnt={onlyTest ? testData.length : contentData.length}
                            loading={loadingDevice}
                        /> : <></>
                    }
                    <div className="col-12 flex justify-content-end">
                        <h4 className="mt-1 mr-5">
                            <span style={{color: 'rgb(220,85,85)'}}>※ 일괄 조명 스위치는 전체 제어(선택 제어)로는 제어 되지 않습니다. 조명 아이콘을 눌러주세요.</span>
                        </h4>
                    </div>
                </div>
            </div>

        </div>
            </Connector>
        </div>
    );
}

export const ModalContent = (props) => {
    switch (props.modalType) {
        case "디바이스 상태":
            return <AttributesContent data={props.propsData}/>
            break;
        case "테스트 기록 조회":
            return <TestRecordList data={props.propsData} modalClose={props.modalClose}/>
            break;
        case "기록 저장":
            return <SaveRecord data={props.propsData} modalClose={props.modalClose}/>
            break;
        case "홈넷 인증":
            return <HomenetAuth data={props.propsData} modalClose={props.modalClose} reload={props.reload}/>
            break;
        case "일괄 제어":
            return <BatchExecute
                data={props.propsData} modalClose={props.modalClose} reload={props.reload}/>
            break;
        case "홈넷 해지":
            return <HomenetDisconnect data={props.propsData} modalClose={props.modalClose} reload={props.reload}   deleteAuth = {props.deleteAuth}/>
            break;
        default:
            break;
    }
}

